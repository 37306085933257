<template>
  <div>未找到此页面，请确认路径无误！</div>
</template>

<script>
export default {
  name: "page404",
  mounted() {

  }
}
</script>

